/* @import url(https://fonts.googleapis.com/css?family=Roboto:100,200,300,500,600,700,800,900); */


.explore-butt-container .btn {
  --shadow: rgba(0, 0, 0, 0.05) 0px 6px 10px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 0px 1px;
  --color: #166e67;
  --gap: 0.5rem;
  --radius: 5px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: var(--gap);
  padding: 0.6rem;
  cursor: pointer;
  border-radius: var(--radius);
  border: none;
  box-shadow: var(--shadow);
  position: relative;
}

.explore-butt-container .bx {
  font-size: 1.1rem;
}

.explore-butt-container .dropdown-outer {
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
  position: absolute;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.5, 1);
  background-color: white;
  box-shadow: var(--shadow);
  display: flex;
  align-items: center;
  justify-content: center;
}

.explore-butt-container .dropdown {
  font-family: "Roboto";
  display: flex;
  width: 100%;
  max-width: 86rem;
}

.explore-butt-container .dropdown-left {
  flex: 0.3;
  padding: 10px 0px;
  position: relative;
}

.explore-butt-container .dropdown-right {
  flex: 1;
  background-color: white;
  padding: 32px 10px;
}

.dropdown-right-panel {
  width: 100%;
}

.dropdown-left-panel .dropdown-right-panel-inner {
  display: flex;
  flex-wrap: wrap;
}

.explore-butt-container .dropdown-left-panel-butt {
  cursor: pointer;
}

.explore-butt-container .dropdown-left-panel-butt>h1 {
  font-size: 16px;
}

.dropdown-right-panel-heading {
  font-size: 24px;
  margin: 0px 10px;
  text-transform: capitalize;
}

.ag-courses_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 1.2rem;

  padding: 30px 10px;

  width: 100%;
  overflow-y: auto;
  max-height: 32rem;
}

.ag-courses_item {
  filter: drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06));
  width: 460px;
  height: 165px;
  overflow: hidden;
  background-color: white;
  border-radius: 20px;
  position: relative;
}

.ag-courses-item_link {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-decoration: none;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding: 16px 20px;
}

.ag-courses-item_university-box {
  display: flex;
  align-items: center;
  gap: 1rem;
  z-index: 5;
  margin-top: 0.5rem;
}

.ag-courses-item_university-box>img {
  /* width: 30px; */
  height: 30px;
}

.ag-courses-item_university-box>h1 {
  font-size: 14px;
  color: rgb(54, 54, 54);
  font-weight: 420;
}

/* .ag-courses-item_link:hover,
.ag-courses-item_link:hover .ag-courses-item_date-box {
  text-decoration: none;
  color: #FFF;
}

.ag-courses-item_link:hover,
.ag-courses-item_link:hover .ag-courses-item_title {
  text-decoration: none;
  color: #FFF;
} */

/* .ag-courses-item_link:hover .ag-courses-item_bg {
  transform: scale(10);
  background-image: url("https://images.unsplash.com/photo-1554469384-e58fac16e23a?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
  background-size: cover;
  background-position: center;
  color: transparent;
} */

.ag-courses-item_title {
  overflow: hidden;
  font-weight: 540;
  font-size: 14px;
  color: rgb(53, 53, 53);

  margin-top: 1rem;

  z-index: 2;
  position: relative;
}

.ag-courses-item_date-box {
  font-size: 12px;
  color: #4c49ea;

  margin-top: 0.5rem;

  z-index: 2;
  position: relative;
  font-weight: 600;
  -webkit-transition: color .5s ease;
  -o-transition: color .5s ease;
  transition: color .5s ease
}

.ag-courses-item_date {
  margin: 0px 5px;
  text-transform: capitalize;
}

.ag-courses-item_bg-status {
  color: white;
  text-transform: capitalize;
  padding: 3px 6px;
  font-weight: 500;
  font-size: 11px;
  z-index: 2;
  position: absolute;
  top: 0;
  left: 10%;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.ag-courses-item_bg {
  height: 128px;
  width: 128px;
  background-color: #4c49ea;
  z-index: 9;
  position: absolute;
  top: -75px;
  right: -75px;
  border-radius: 50%;
  transition: all .5s ease;
}

.ag-courses-item_bg-text {
  height: 100%;
  width: 100%;
  color: white;
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: all .5s ease;
  opacity: 0;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.6rem;
  backdrop-filter: blur(2px);
  background-color: rgba(0, 0, 0, 0.365);
}

.ag-courses-item_bg-text .fancy {
  font-size: 18px;
  text-align: center;
  font-weight: 500;
}

.ag-courses-item_link:hover,
.ag-courses-item_link:hover .ag-courses-item_bg-text {
  opacity: 1;
}


.ag-courses_item:nth-child(2n) .ag-courses-item_bg {
  background-color: #3ecd5e;
}

.ag-courses_item:nth-child(3n) .ag-courses-item_bg {
  background-color: #e44002;
}

.ag-courses_item:nth-child(4n) .ag-courses-item_bg {
  background-color: #952aff;
}

@media only screen and (max-width: 979px) {
  .ag-courses_item {
    -ms-flex-preferred-size: calc(50% - 30px);
    flex-basis: calc(50% - 30px);
  }

  .ag-courses-item_title {
    font-size: 24px;
  }
}

@media only screen and (max-width: 767px) {
  .ag-format-container {
    width: 96%;
  }

}

@media only screen and (max-width: 639px) {
  .ag-courses_item {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }

  .ag-courses-item_title {
    min-height: 72px;
    line-height: 1;

    font-size: 24px;
  }

  .ag-courses-item_link {
    padding: 22px 40px;
  }

  .ag-courses-item_date-box {
    font-size: 16px;
    text-transform: capitalize;
  }
}

:root {
  --background: white;
  --navbar-width: 100%;
  --navbar-width-min: 180px;
  --navbar-dark-primary: #18283b;
  --navbar-dark-secondary: #2c3e50;
  --navbar-light-primary: #f5f6fa;
  --navbar-light-secondary: #8392a5;
}



#nav-content {
  padding: 16px 0;
  position: relative;
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  color: black;
  flex-direction: column;
  gap: 0.4rem;
  overflow-y: auto;
  max-height: 30rem;
  transition: width 0.2s;
}


.nav-button {
  position: relative;
  height: 44px;
  direction: ltr;
  cursor: pointer;
  z-index: 1;
}

.nav-button-inner {
  text-transform: capitalize;
  border-radius: 5px;
  /* border-top-left-radius: 5px; */
  /* border-bottom-left-radius: 5px; */
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  transition: all 0.1s ease-in;
  clip-path: polygon(0% 0%, 95% 0%, 100% 50%, 95% 100%, 0% 100%);
}

.nav-button:hover .nav-button-inner {
  color: white;
  background-color: #4c49ea;
}

.nav-button-inner-img{
  width: 20px;
  height: 20px;

}