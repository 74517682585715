.collabs {
  width: 100%;
  text-align: center;
  margin-top: 10px;
  overflow: hidden;
  /* background-color: aqua; */
}

.collabs>h4 {
  color: rgb(100, 100, 100);
  font-size: 18px;
  font-weight: 500;
}

.collabs-inner {
  display: flex;
  align-items: end;
  justify-content: center;
  gap: 34px;
  flex-wrap: wrap;
  padding: 20px 0px;
}

.collabs-inner>div {
  flex: 1;
  max-width: 160px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.collabs-inner>div>img {
  width: 100%;
  /* height: 100%; */
}
