/* .nav-3 {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0.6rem 0rem;
} */

.nav-3 .nav-3-inner {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 85rem;
  min-height: 60px;
}

.nav-3-logo {
  /* background-color: #3AA39F; */
  /* width: 140px; */
  display: flex;
  flex-direction: column;
  text-align: center;
  width: fit-content;
}

.nav-3-logo>h1 {
  color: #4461F2;
  font-weight: 700;
  font-size: 28px;
  line-height: 30px;
}

.nav-3-logo>h6 {
  color: rgb(154, 154, 154);
  font-weight: 500;
  font-size: 9px;
  line-height: 9px;
}

.nav-3-left {
  flex: 0.7;
  display: flex;
  gap: 2rem;
  align-items: center;
}

.nav-3-right {
  flex: 1;
  display: flex;
  gap: 1rem;
  justify-content: end;
  align-items: center;
}

.nav-3-right .nav-3-right-inner {
  display: none;
  gap: 2rem;
  align-items: center;
}


@media screen and (min-width: 1280px) {
  .nav-3-right .nav-3-right-inner {
    display: flex;
  }
}

.nav-3-inner-butt {
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
}

.nav-3-inner-butt>h1 {
  color: #1a1a1a;
  white-space: nowrap;
  font-size: 0.875rem;
  font-weight: 650;
  line-height: 1.25rem;
  cursor: pointer;
}

.nav-3 .nav-3-auth {
  /* --primary-color: #0056D2; */
  --primary-color: #1A73E8;
  --secondary-color: #fff;
  --hover-color: #1B66C9;
  --arrow-width: 10px;
  --arrow-stroke: 2px;
  box-sizing: border-box;
  border: 0;
  color: var(--secondary-color);
  padding: 0.85rem 1.6rem;
  background: var(--primary-color);
  border-radius: 20px;
  display: flex;
  transition: 0.2s background;
  align-items: center;
  gap: 0.6rem;
  white-space: nowrap;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.25rem;
  cursor: pointer;
}

.nav-3 #login {
  --secondary-color: #000;
  --hover-color: #111;
  --arrow-width: 10px;
  --arrow-stroke: 2px;
  --primary-color: #fff;
  border-radius: 20px;
  box-sizing: border-box;
  border: 0;
  color: #4461F2;
  /* text-decoration: underline; */
  padding: 0.85rem 1.6rem;
  background: transparent;
  display: flex;
  transition: 0.2s background;
  align-items: center;
  font-size: 0.9rem;
  gap: 0.6em;
  /* font-weight: bold; */
}

.nav-3 .nav-3-auth .arrow-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-3 .nav-3-auth .arrow {
  margin-top: 2px;
  width: var(--arrow-width);
  background: var(--primary-color);
  height: var(--arrow-stroke);
  position: relative;
  transition: 0.2s;
}

.nav-3 .nav-3-auth .arrow::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  border: solid var(--secondary-color);
  border-width: 0 var(--arrow-stroke) var(--arrow-stroke) 0;
  display: inline-block;
  top: -3px;
  right: 3px;
  transition: 0.2s;
  padding: 3px;
  transform: rotate(-45deg);
}

.nav-3 .nav-3-auth:hover {
  background-color: var(--hover-color);
}

#login:hover {
  text-decoration: underline;
  background-color: #fff;
}

.nav-3 .nav-3-auth:hover .arrow {
  background: var(--secondary-color);
}

.nav-3 .nav-3-auth:hover .arrow:before {
  right: 0;
}

.button-explore {
  --color: #4461F2;
  display: flex;
  align-items: center;
  gap: 0.2rem;
  padding: 0.8em 1em;
  background-color: transparent;
  border-radius: 20px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  /* transition: .01s; */
  font-weight: 400;
  font-size: 14px;
  border: 2px solid;
  font-family: inherit;
  font-weight: 600;
  text-transform: uppercase;
  color: var(--color);
  z-index: 1;
}

.button-explore::before,
.button-explore::after {
  content: '';
  display: block;
  width: 40px;
  height: 40px;
  transform: translate(-50%, -50%);
  position: absolute;
  border-radius: 50%;
  z-index: -1;
  background-color: var(--color);
  transition: 1s ease;
}

.button-explore::before {
  top: -1em;
  left: -1em;
}

.button-explore::after {
  left: calc(100% + 1em);
  top: calc(100% + 1em);
}

.button-explore:hover::before,
.button-explore:hover::after {
  height: 410px;
  width: 410px;
}

.button-explore:hover {
  color: white;
}

.button-explore:active {
  filter: brightness(.8);
}

.button-explore-icon {
  transition: all 0.15s ease-in-out;
}

.explore-2-butt:hover .button-explore-icon {
  rotate: 180deg;
}


.nav-main-inner-butt-left {
  background: rgb(255, 255, 255);
  background: linear-gradient(90deg, rgb(255, 255, 255) 45%, rgba(255, 255, 255, 0.324) 100%);
}

.nav-main-inner-butt-right {
  background: rgb(255, 255, 255);
  background: linear-gradient(90deg, rgb(255, 255, 255) 45%, rgba(255, 255, 255, 0.324) 100%);
}