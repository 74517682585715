/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,100;9..40,200;9..40,300;9..40,400;9..40,500;9..40,600;9..40,700;9..40,800;9..40,900;9..40,1000&display=swap"); */

@font-face {
  font-family: "circular";
  src: url("../src/Assets/fonts/circular/CircularXXWeb-Book.woff2");
}

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0px !important;
  overflow: unset;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* background: rgba(32, 178, 171, 0.187); */
}

/* .disabledEnroll{
  background-color: #9CA3AF; 
  color: #ffffff; 
  border: 0;
  cursor: not-allowed;
}

.disabledEnroll:hover {
  background-color: #9CA3AF; 
} */

.pop-content {
  min-width: 200px !important;
  width: auto !important;
  position: relative !important;
  padding: 4px;
}

.pop-content-user {
  min-width: 180px !important;
  width: auto !important;
  position: relative !important;
  padding: 4px;
}

.accordion-parent {
  padding: 0% !important;
  margin: 0% !important;
  min-height: auto !important;
  border: none !important;
  box-shadow: none !important;
}

.accordion-parent::before {
  display: none !important;
}

.accordion-summary {
  padding: 0 !important;
  margin: 0% !important;
}

/*.accordion-parent div, p, h2{
  border: none !important;
  box-shadow: none !important;
  padding: 0% !important;
  margin: 0% !important;
  min-height: auto !important;
} */

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace; */
}

.main-container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.main-container-2 {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 80px;
  /* background-color: aqua; */
}

.main-container-3 {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 70px;
  /* background-color: aqua; */
}

.main-container-4 {
  width: 100%;
  /* min-height: 70vh; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}

.home-4-main {
  width: 100%;
  position: relative;
}
.custom-scrollbar::-webkit-scrollbar {
  width: 0.4rem;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: rgba(212, 211, 211, 0.75);
  opacity: 0.5;
  border-radius: 1rem;
  transition: all 0.2s;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: theme("colors.neutral.300");
  opacity: 1;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.react-tel-input .form-control:focus {
  outline: none !important;
  box-shadow: none !important;
  border-bottom: 2px solid #1a73e8 !important;
}

.artibot-launcher-bubble--1dzBs.artibot-launcher-bottom-right--2_XHM.artibot-launcher-bubble-sans--1Excy {
  display: none !important;
}
.artibot-launcher--dBP9o.artibot-launcher-bottom-right--2_XHM {
  display: none !important;
}

/* .app.header {
  width: 40px !important;
  height: 40px !important;
} */
