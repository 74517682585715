:root {
  --surface-color: #fff;
  --curve: 40;
}

.card__arc {
  width: 80px;
  height: 80px;
  position: absolute;
  bottom: 100%;
  right: 0;
  z-index: 1;
}

.card__arc path {
  fill: var(--surface-color);
  d: path("M 40 80 c 22 0 40 -22 40 -40 v 40 Z");
}

/* .card__arc path {
  fill: var(--surface-color);
  d: path("M 20 80 A 20 20 0 0 1 40 60 L 40 80 Z");
} */
